import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

export function tokenLifetime(token) {
  const { exp } = jwtDecode(token);

  return exp - Math.floor(Date.now() / 1000);
}

export function isTokenExpired(token) {
  if (!token) return true;

  const { exp } = jwtDecode(token);

  return exp - Math.floor(Date.now() / 1000) <= 0;
}

export function isTokenCloseToExpiry(token) {
  if (!token) return false;

  const { exp } = jwtDecode(token);

  return exp - Math.floor(Date.now() / 1000) < 2 * 60 && !!Cookies.get('access');
}

export function setToken(type, token, expiration) {
  Cookies.set(type, token, { expires: expiration });
}

export function clearToken(type) {
  Cookies.remove(type);
}

export function getToken(type) {
  return Cookies.get(type);
}
