import * as Sentry from '@sentry/nextjs';
import getConfig from 'next/config';

const { SENTRY_DSN, SENTRY_ENV, BRANCH } = getConfig().publicRuntimeConfig;

Sentry.init({
  dsn: SENTRY_DSN,
  enabled: SENTRY_ENV !== 'local',
  environment: SENTRY_ENV || BRANCH,
  tracesSampleRate: 0.01,
  normalizeDepth: 10,
});
