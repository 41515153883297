import PropTypes from 'prop-types';
import React from 'react';

import { variantKeys } from '@common/alert';

import {
  Content, StyledCloseButton, Text, Wrapper, WrapperInner,
} from './Alert.styled';

export const Alert = React.forwardRef(({ variant, children, onClose }, ref) => (
  <Wrapper
    variant={variant}
    ref={ref}
  >
    <WrapperInner variant={variant}>
      <Content data-testid={`alert-${variant}`}>
        <Text variant="body3">{Array.isArray(children) ? children.join('\n') : children}</Text>
        <StyledCloseButton
          isSmall
          type="button"
          onClick={onClose}
        />
      </Content>
    </WrapperInner>
  </Wrapper>
));

Alert.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(variantKeys),
};

Alert.defaultProps = {
  children: undefined,
  onClose: undefined,
  variant: variantKeys[0],
};

export default Alert;
