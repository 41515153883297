import styled, { css } from 'styled-components';

import {
  getGridBase, getThemeColor, getThemeTransition, media,
} from '@utils/styled';

import Typography from '@common/components/Typography';
import BackChevronLink from '@common/components/BackChevronLink';
import { NAVBAR_HEIGHT, STICKY_HEADER_HEIGHT } from '@common/constants/ui';
import { BODY_RESIZING_CLASS } from '@common/constants/body';

const getElementClip = shouldShowElement => {
  if (shouldShowElement) {
    return 'inset(0 -100% 0 -100%)';
  }

  return 'inset(0 -100% 100% -100%)';
};

const wrapperWithBackgroundStyles = css`
  background-color: ${getThemeColor('grey.100')};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 4;
    display: block;
    margin-right: -100%;
    margin-left: -100%;
    background-color: ${getThemeColor('grey.100')};
  }
`;

export const Wrapper = styled.div`
  ${getGridBase()};
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 17;
  max-width: ${({ theme }) => theme.breakpoints[0]}rem;
  height: ${({ alertHeight }) => `calc(${STICKY_HEADER_HEIGHT}rem + ${alertHeight}px)`};
  margin: 0 auto;
  ${({
    isHero, isProduct, isMobileHero, isTransparent,
  }) =>
    (isHero || isProduct || isMobileHero || isTransparent ?
      css`
          background-color: transparent;
        ` :
      wrapperWithBackgroundStyles)}
  transition: ${getThemeTransition('transform', 'fast')};
  transform: ${({ isMobileApp }) =>
    (!isMobileApp ? `translateY(${NAVBAR_HEIGHT}rem)` : 'translateY(0)')};
  ${({ theme }) =>
    theme.getFluidSpacing('padding-left', 'widthBased', 0, false, 'safe-area-inset-left')};
  ${({ theme }) =>
    theme.getFluidSpacing('padding-right', 'widthBased', 0, false, 'safe-area-inset-right')};
  ${({ isScrollingDown }) =>
    isScrollingDown &&
    css`
      transform: translateY(0);
    `}

  ${media.from880up`
    ${({ isProduct, isTransparent }) => isProduct && !isTransparent && wrapperWithBackgroundStyles}
  `}

  ${media.from1200up`
    ${({ isMobileHero, isTransparent }) =>
    isMobileHero && !isTransparent && wrapperWithBackgroundStyles}
  `}

  ${media.from1600up`
    ${({ isHero, isTransparent }) => isHero && !isTransparent && wrapperWithBackgroundStyles}
  `}
`;

export const BackButton = styled(BackChevronLink)`
  position: absolute;
  top: calc(
    ${({ isAlertMessagesVisible, notAnimated, alertHeight }) =>
    `50% + ${isAlertMessagesVisible && notAnimated && alertHeight ? alertHeight / 2 : 0}px`}
  );
  left: -1.2rem;
  z-index: 4;
  display: inline-flex;
  width: 4rem;
  height: 4rem;
  box-shadow: ${({
    isVisible, theme, isMobileHero, isProduct,
  }) =>
    (!isVisible && (isMobileHero || isProduct) && theme.shadows[0]) || 'none'};
  margin: ${({ isStickyTitleVisible }) => (isStickyTitleVisible ? '0.9rem 0 0 1.2rem' : 0)};
  border-radius: 4rem;
  padding: ${({ isStickyTitleVisible }) => (isStickyTitleVisible ? 0 : '0.9rem 0 0 1.2rem')};
  background: ${getThemeColor('grey.100')};
  transition: ${getThemeTransition('visibility', 'fast')};
  transform: translateY(-50%);

  ${({ isVisible }) =>
    isVisible &&
    css`
      visibility: hidden;
    `}

  ${media.from880up`
    box-shadow: ${({
    isVisible, theme, isMobileHero, isProduct,
  }) =>
    (!isVisible && isMobileHero && !isProduct && theme.shadows[0]) || 'none'};
  `}

  ${media.from1200up`
    box-shadow: ${({ isVisible, theme, isHero }) =>
    (!isVisible && isHero && theme.shadows[0]) || 'none'};
  `}

  ${media.from1600up`
    box-shadow: ${({ isHeroAlwaysExpanded, theme, isVisible }) =>
    (!isVisible && isHeroAlwaysExpanded && theme.shadows[0]) || 'none'};
  `}
`;

const animatedBarOffset = ({ alertHeight }) =>
  alertHeight &&
  css`
    top: ${alertHeight}px;
  `;

export const AnimatedBar = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  display: flex;
  align-items: center;
  max-width: 100%;
  height: ${STICKY_HEADER_HEIGHT}rem;
  will-change: clip-path;
  clip-path: ${({ isVisible }) => getElementClip(isVisible)};
  ${({ theme }) =>
    theme.getFluidSpacing('margin-left', 'widthBased', 0, false, 'safe-area-inset-left')};
  ${({ theme }) =>
    theme.getFluidSpacing('margin-right', 'widthBased', 0, false, 'safe-area-inset-right')};
  transition: ${getThemeTransition('clip-path', 'slow')}, ${getThemeTransition('top', 'fast')};

  ${animatedBarOffset};

  body.${BODY_RESIZING_CLASS} & {
    transition: none;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
  }

  &::before {
    z-index: 3;
    height: 1px;
    background-color: ${getThemeColor('bluegrey.90')};
  }

  &::after {
    top: 0;
    z-index: 2;
    margin-right: -100%;
    margin-left: -100%;
    background-color: ${getThemeColor('grey.100')};
    will-change: clip-path;
    clip-path: ${({ isVisible }) => getElementClip(isVisible)};
    transition: ${getThemeTransition('clip-path', 'slow')};
  }

  & ${BackButton} {
    z-index: 10;
    visibility: visible;
  }
`;

export const StickyTitle = styled(Typography).attrs({
  customAs: 'span',
  variant: 'paragraph2',
  maxLines: 1,
})`
  position: relative;
  z-index: 10;
  display: block;
  width: 100%;
  margin: 0;
  margin-left: 3.1rem;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StickyControls = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  margin-left: 1rem;
`;
