import styled from 'styled-components';

import { getThemeColor } from '@utils/styled';

import { ArrowBackwardNavigation } from '@common/components/Icons';

export const Container = styled.div`
  line-height: 0;
`;

export const IconWrapper = styled.a`
  display: inline-block;
  cursor: pointer;
`;

export const ChevronIcon = styled(ArrowBackwardNavigation)`
  fill: ${getThemeColor('bluegrey.70')};
  display: block;
`;
