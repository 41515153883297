/* eslint-disable global-require */
import { combineReducers } from 'redux';

import { isBrowser } from '@utils/environmentCheck';

import { DELIVERY_OPTIONS } from '@common/constants/giftCards';

import discount from './discount';
import cardValues from './cardValues';
import order from './order';
import payment from './payment';

const giftCardsReducer = combineReducers({
  discount,
  cardValues,
  order,
  payment,
});

const stateReconciler = (inboundState, originalState, reducedState) => {
  if (!inboundState || typeof inboundState !== 'object') {
    return { ...reducedState };
  }

  let newState = {
    ...reducedState,
    order: {
      ...reducedState.order,
      ...inboundState.order,
      address: {
        ...inboundState.order?.address,
        ...reducedState.order.address,
      },
    },
    payment: {
      ...inboundState.payment,
    },
  };

  if (typeof newState.order.directDelivery !== 'undefined') {
    const addressKey =
      newState.order.directDelivery === DELIVERY_OPTIONS.TO_MY_HOME ?
        'shippingAddress' :
        'invoiceAddress';
    const { isSameAddressForInvoice } = inboundState.order?.address;

    newState = {
      ...newState,
      order: {
        ...newState.order,
        address: {
          ...newState.order.address,
          [addressKey]: {
            ...newState.order.address?.[addressKey],
            ...inboundState.order?.address?.[addressKey],
          },
          isSameAddressForInvoice,
        },
      },
    };
  }

  return newState;
};

export default (() => {
  if (!isBrowser) {
    return giftCardsReducer;
  }

  const { persistReducer } = require('redux-persist');
  const { default: storage } = require('redux-persist/lib/storage');

  return persistReducer(
    {
      key: 'giftCards',
      whitelist: ['order', 'payment'],
      storage,
      stateReconciler,
    },
    giftCardsReducer
  );
})();
