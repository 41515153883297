export const activate = () => '/auth/users/activation/';
export const activationResend = () => '/auth/users/resend-activation/';
export const address = customerId => `/customers/${customerId}/address/`;
export const barcode = customerId => `/customers/${customerId}/barcode/`;
export const categories = () => '/products/categories/';
export const categoryIcons = () => '/category-icons/';
export const cardEmail = cardNr => `/auth/cards-check/${cardNr}/`;
export const cmsLookbooksPage = pathname => `/cms/pages/find/?html_path=lookbooks/${pathname}/`;
export const cmsPage = pathname => `/cms/pages/find/?html_path=${pathname}/`;
export const cmsPages = () => '/cms/pages/';
export const cmsLatestNews = () => '/cms/pages/latest-news/';
export const cmsLatestLookbook = () => '/cms/pages/latest-lookbook/';
export const cmsCustomerStoresNews = () => '/cms/pages/store-news/';
export const cmsPartial = partialName => `/cms/partials/${partialName}/`;
export const createCustomer = () => '/customers/';
export const deleteCustomer = customerId => `/customers/${customerId}/send-deletion-request/`;
export const customerBadge = customerId => `/customers/${customerId}/badge/`;
export const customerDetail = customerId => `/customers/${customerId}/`;
export const customerInfo = customerId => `/customers/${customerId}/info/`;
export const customerShops = customerId => `/customers/${customerId}/stores/`;
export const customerMobileSubscriptions = customerId =>
  `/customers/${customerId}/mobile-subscriptions/`;
export const emailChange = () => '/auth/users/reset-email/';
export const emailChangeConfirm = () => '/auth/users/reset-email-confirm/';
export const externalUID = () => '/auth/users/me/';
export const favorites = customerId => `/customers/${customerId}/favorites/`;
export const favoritesBulkAdd = customerId => `/customers/${customerId}/favorites/bulk-add/`;
export const favoritesBulkDelete = customerId => `/customers/${customerId}/favorites/bulk-delete/`;
export const filters = () => '/product-filter/';
export const goodieOpen = (customerId, goodieId) =>
  `/customers/${customerId}/goodies/${goodieId}/open/`;
export const goodies = customerId => `/customers/${customerId}/goodies/`;
export const goodiesByTemplate = (customerId, template) =>
  `/customers/${customerId}/goodies/?template=${template}`;
export const goodiesDetails = (customerId, id) => `/customers/${customerId}/goodies/${id}/`;
export const goodiesTemplate = id => `/goodie-templates/${id}/`;
export const homepage = () => '/cms/pages/homepage/';
export const infoModal = modalName => `/cms/info-modals/${modalName}/`;
export const jobsAreaOfOperationList = () => '/jobs/area-of-operations/';
export const jobsAreaOfOperationDetails = id => `/jobs/area-of-operations/${id}/`;
export const jobsCreateApplication = () => '/jobs/applications/';
export const jobsUpdateApplication = uuid => `/jobs/applications/${uuid}/`;
export const jobsOfferDetails = id => `/jobs/job-offers/${id}/`;
export const jobsOffersList = areaOfOperationId =>
  `/jobs/job-offers/${areaOfOperationId ? `?area_of_operation=${areaOfOperationId}` : ''}`;
export const jobsUploadApplicationDocument = uuid => `/jobs/applications/${uuid}/documents/`;
export const jobsDeleteApplicationDocument = (uuid, id) =>
  `/jobs/applications/${uuid}/documents/${id}/`;
export const login = () => '/auth/login/';
export const menusList = () => '/cms/menus/';
export const menuDetails = identifier => `/cms/menus/${identifier}/`;
export const newsletterEmailAgreement = customerId =>
  `/customers/${customerId}/allow-newsletter-email/`;
export const newsletterPushAgreement = customerId =>
  `/customers/${customerId}/allow-newsletter-push/`;
export const newsletterSmsAgreement = customerId =>
  `/customers/${customerId}/allow-newsletter-sms/`;
export const newsletterRegistration = () => '/newsletter-registration/';
export const newsletterRegistrationConfirm = id => `/newsletter-registration/${id}/confirm/`;
export const newsletterUnregister = id => `/newsletter-registration/${id}/`;
export const notificationSettings = (customerId, notificationId) =>
  `/customers/${customerId}/notifications-settings/${notificationId}/`;
export const notificationsSettings = customerId =>
  `/customers/${customerId}/notifications-settings/`;
export const disableAllEmailsSettings = customerId => `/customers/${customerId}/disable-all-email/`;
export const disableAllPushSettings = customerId => `/customers/${customerId}/disable-all-push/`;
export const disableAllSmsSettings = customerId => `/customers/${customerId}/disable-all-sms/`;
export const passwordChange = () => '/auth/users/set-password/';
export const passwordReset = () => '/auth/users/reset-password/';
export const passwordResetConfirm = () => '/auth/users/reset-password-confirm/';
export const personalizedAdsAgreement = customerId =>
  `/customers/${customerId}/allow-personalized-ads/`;
export const postalDeliveryAgreement = customerId =>
  `/customers/${customerId}/allow-postal-delivery/`;
export const product = id => `/products/${id}/`;
export const productAvailability = productColorId => `/products/${productColorId}/availability/`;
export const products = () => '/products/';
export const profilePicture = customerId => `/customers/${customerId}/profile-image/`;
export const register = () => '/auth/users/';
export const registerWithCard = () => '/auth/users/card-signup/';
export const settingsUpdate = customerId => `/customers/${customerId}/settings-update/`;
export const settingsUpdateMute = customerId => `/customers/${customerId}/settings-update-mute/`;
export const shop = id => `/stores/${id}/`;
export const shops = () => '/stores/';
export const termsAndConditionsAgreement = customerId => `/customers/${customerId}/terms/accept/`;
export const termsAndConditionsLatest = () => '/cms/pages/terms/latest/';
export const transactionDetails = (customerId, transactionId) =>
  `/customers/${customerId}/transactions/${transactionId}/`;
export const transactions = customerId => `/customers/${customerId}/transactions/`;
export const transactionsCashRelevant = customerId =>
  `/customers/${customerId}/transactions/cash-relevant/`;
export const vouchers = customerId => `/customers/${customerId}/vouchers/`;
export const verifyAddress = token => `/customers/verify-address/${token}/`;
export const mobileProviders = () => '/mobile/providers/';
export const mobileOrderFormSend = () => '/mobile/sign-up/';
export const mobilePlan = mobilePlanId => `/mobile/plans/${mobilePlanId}/`;
export const mobilePlans = () => '/mobile/plans/';
export const mobileTermsLinks = () => '/mobile/terms/';
export const mobileFAQ = faqId => `/mobile/faq/${faqId}/`;
export const mobileMainPage = () => '/cms/pages/mobileindexpage/';
export const shopEventPage = () => '/cms/pages/shopeventpage/';
export const shopEventProductDetails = productId => `/moments/products/${productId}/`;
export const shopEventStatus = () => '/shop-event-information/';
export const shopEventProducts = () => '/moments/product-variations/';
export const shopEventUserCount = () => '/user-count/';
export const basketCalculations = () => '/basket/calculate/';
export const basketPaymentMethods = () => '/payments/methods/';
export const submitOrder = () => '/orders/';
export const deliveryConditionsPage = () => '/cms/pages/deliveryconditions/';
export const defaultMetaInfo = () => '/cms/pages/defaultmetainfo/';
export const giftCardsPage = () => '/cms/pages/giftcardorderpage/';
export const storeNews = () => '/cms/pages/store-news/';
export const giftCardsDiscount = () => '/gift-card/discount-period/';
export const giftCardValues = () => '/gift-card/value-options/';
export const submitGiftCardsOrder = () => '/gift-card/order/';
export const giftCardBalanceCheck = () => '/gift-card/check-balance/';
export const geoLocation = () => '/api/search/location/';
export const getDeviceInfo = () => '/api/devicecheck/';
export const productDescription = () => '/product-description/';
export const wheelOfFortuneConfig = webToken => `/wheel-of-fortune/config-web/${webToken}/`;
export const wheelOfFortuneWebToken = customerId =>
  `/customers/${customerId}/helpers/generate-wheel-of-fortune-web-validation-token/`;
export const wheelOfFortunePlay = webToken => `/wheel-of-fortune/play-web/${webToken}/`;
