import {
  getVouchersRequest,
  getVouchersSuccess,
  getVouchersFailure,
  getVIPInfoModalRequest,
  getVIPInfoModalSuccess,
  getVIPInfoModalFailure,
  clearVouchers,
} from '@actions/vouchers';
import { selectGetUID } from '@selectors/auth';

import request from '@utils/request';

import { vouchers, infoModal } from '@common/endpoints';

export const fetchVouchers = (offset = 0, limit = 0) => (dispatch, getState) => {
  const customerId = selectGetUID(getState());

  dispatch(getVouchersRequest());

  return request
    .get(vouchers(customerId), {
      params: {
        limit,
        offset,
      },
    })
    .then(({ data }) => {
      if (offset === 0) {
        dispatch(clearVouchers());
      }

      return data;
    })
    .then(data => {
      dispatch(getVouchersSuccess(data));
    })
    .catch(error => {
      dispatch(getVouchersFailure(error));
    });
};

export const fetchInfoModal = modalName => dispatch => {
  dispatch(getVIPInfoModalRequest());

  return request
    .get(infoModal(modalName))
    .then(({ data }) => {
      dispatch(getVIPInfoModalSuccess(data));
    })
    .catch(error => {
      dispatch(getVIPInfoModalFailure(error));
    });
};
