import { breakpoints } from '@themes/main';

export function getFluidSizingFormula(
  minSize,
  maxSize,
  minBreakpoint = breakpoints[0],
  maxBreakpoint = breakpoints[18],
  unit = 'rem',
  includedEnv = '',
  negative = false
) {
  // eslint-disable-next-line max-len
  return `calc(${
    includedEnv ? `${negative ? '-1 * ' : ''}env(${includedEnv}) + ` : ''
  }${minSize}${unit} + ${maxSize - minSize} * ((100vw - ${maxBreakpoint}${unit}) / ${
    minBreakpoint - maxBreakpoint
  }));`;
}
