import PropTypes from 'prop-types';
import React from 'react';

import { Text } from './Typography.styled';

const tagsMap = {
  body1: 'p',
  body2: 'p',
  body3: 'p',
  body4: 'p',
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  paragraph1: 'p',
  paragraph2: 'p',
  subtitle1: 'p',
  subtitle2: 'p',
};

export const typographyVariants = Object.keys(tagsMap);

const Typography = React.forwardRef(({
  children, variant, customAs, ...extraStyling
}, ref) => {
  // prop customAs - required to deep pass "as" component through many styled() extends:
  // https://github.com/styled-components/styled-components/issues/2129
  const as = customAs || tagsMap[variant];

  return (
    <Text
      as={as}
      variant={variant}
      ref={ref}
      {...extraStyling}
    >
      {children}
    </Text>
  );
});

Typography.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]).isRequired,
  customAs: PropTypes.elementType,
  variant: PropTypes.oneOf(typographyVariants),
  maxLines: PropTypes.number,
};

Typography.defaultProps = {
  customAs: undefined,
  variant: 'body2',
  maxLines: undefined,
};

export default Typography;
